<template>
  <div class="app">
    <div class="ticker">
      <img src="@/assets/face.png" alt="face">
      <span>WARRIOR</span>
    </div>
    <div class="content">
      <h1 class="content_title">
        <img src="@/assets/stars1.png" alt="">
        $WARRIOR
        <img src="@/assets/stars2.png" alt="">
      </h1>
      <span class="content_ca">CA will appear soon</span>
      <div class="content_main">
        <div class="content_main_text">
          <p>
            <img class="content_main_text_pic" src="@/assets/face1.jpg" alt="face">

            I am a trench warrior, the ultimate degen trader, constantly flipping memecoins in a wild chase for 1000x gains. I thrive in the chaos of the crypto trenches, taking insane risks and diving headfirst into the latest meta. Every day is a new battlefield, and I am always on the hunt, hoping to strike it rich with the next big explosion. Fueled by adrenaline and dreams of generational wealth, I am a true renegade of the crypto world, living life on the edge and embracing the madness of the market.          </p>
        </div>
        <div class="content_main_links">
          <span class="content_main_links_link">TWITTER</span>
          <span class="content_main_links_link">TELEGRAM</span>
          <span class="content_main_links_link">DEXSCREENER</span>
        </div>
      </div>
      <footer>
        2024
      </footer>
      <img class="guts" src="@/assets/guts.png" alt="guts">
      <img class="guts2" src="@/assets/guts2.png" alt="guts">
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="sass">
*
  margin: 0
  padding: 0
  box-sizing: border-box
body
  background-image: url("@/assets/bg.jpg")
  background-size: 100vh
  background-attachment: fixed
  overflow-x: hidden
.app
  width: 100vw
  overflow-x: hidden
  display: flex
  align-items: center
  justify-content: center
  font-family: "Inconsolata", monospace
  //font-family: "Teko", sans-serif
  cursor: url("@/assets/cursor.png") 0 0, auto
.content
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-family: "Teko", sans-serif
  padding: 60px 5vw 10vw 15vw
  gap: 40px
  cursor: url("@/assets/cursor.png") 0 0, auto
  position: relative
  background-image: linear-gradient(30deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))
  &_title
    font-family: "Playfair Display", serif
    font-weight: 500
    font-size: clamp(48px, 8vw, 160px)
    border: 2px solid #efefef
    border-radius: 3vw
    display: flex
    align-items: center
    justify-content: center
    z-index: 2
    position: relative
    letter-spacing: -0.2vw
    background: url('@/assets/text-bg.jpg')
    background-size: cover
    background-position: left
    color: transparent
    -webkit-font-smoothing: antialiased
    -webkit-background-clip: text
    -moz-background-clip: text
    background-clip: text
    &::after
      content: "TRENCHES"
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      font-size: clamp(19.2px, 2.4vw, 48px)
      text-shadow: none
      color: #efefef
      font-weight: 100
    & img
      width: 7%
      margin: 0 2% 5%
  &_ca
    color: #ffffff
    font-weight: 300
    font-size: clamp(20px, 3vw, 60px)
  &_main
    display: flex
    justify-content: center
    max-width: 800px
    gap: 20px
    &_text
      font-family: "Inconsolata", monospace
      line-height: 30px
      color: #efefef
      font-size: 12pt
      z-index: 10
      &_pic
        float: right
        width: 10vw
        min-width: 100px
        border-radius: 0.5vw
        border: 1px solid #efefef
        margin: 10px
    &_links
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: space-between
      &_link
        //text-shadow: 0.04vw 0.04vw #777777, -0.04vw 0.04vw #777777, 0.04vw -0.04vw #efefef, -0.04vw -0.04vw #efefef
        color: #8c0b0b
        font-size: clamp(50px, 6vw, 60px)
        font-weight: 300
        transition: all 0.3s ease
        &:hover
          color: #efefef
        //text-shadow: 1px 1px #777777, -1px 1px #777777, 1px -1px #efefef, -1px -1px #efefef
  & footer
    position: absolute
    bottom: 0
    height: 80px
    display: flex
    align-items: center
    justify-content: center
    color: #efefef
    //border-top: 1px solid #efefef
    width: 100%
    font-size: 20pt
    //background-color: black
.guts
  position: absolute
  bottom: 0
  left: 10vw
  width: 20vw
  min-width: 190px
.guts2
  position: absolute
  bottom: 0
  right: 0
  width: 20vw
  min-width: 190px
.ticker
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  width: 10vw
  min-height: 100vh
  //background-color: black
  background-image: url("@/assets/bg.jpg")
  background-size: cover
  border-right: 2px solid #efefef
  writing-mode: vertical-lr
  color: #efefef
  font-family: "Orbitron", sans-serif
  font-weight: 700
  font-size: 7.5vw
  z-index: 2
  & img
    width: 80%
@media (max-width: 740px)
  .content
    &_main
      flex-direction: column
      align-items: center
      &_links
        align-items: center
        z-index: 10
        margin-bottom: 70%
</style>